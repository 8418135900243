/* You can add global styles to this file, and also import other style files */
@import '@idexx/spot/dist/styles/shared/_spot_tokens.scss';
@import '@idexx/spot/dist/styles/spot_library.css';

html {
  // zoom: 90%;
}

a {
  cursor: pointer;
}

body {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.spot-di {
  margin-left: 0px;
}

label:focus {
  outline: none;
}

.spot-long-form-text ul,
.spot-long-form-text ol {
  margin: 0;
}

.hint-alert {
  color: orangered;
}

.spot-form__microcopy {
  color: #a7a7a7;
  display: inline-block;
  height: 15px;
  margin: 5px 0;
  padding: 5px 0 0 8px;
}

#customerCode {
  .spot-form__microcopy * {
    color: $spot-color-red-60;
  }
}

.spot-icon {
  height: 23px;
  width: 23px;
}

.spot-form--error {
  .spot-form__select-open-icon {
    fill: $spot-color-red-60 !important;
  }
}

.spot-form__label-required-indicator {
  font-size: 22px;
  line-height: 0.9em;
  text-decoration: none;
  vertical-align: text-top;
}

// Order entry specific modal style overrides
#orderValidationDetailsModal {
  .sample-association-body {
    &:last-child {
      margin-bottom: 40px !important;
    }
  }

  .spot-modal__content {
    overflow-y: hidden !important;
  }

  .spot-modal__copy {
    padding: 0 !important;
  }

  .order-validation-modal-header {
    padding: 24px 0 24px 20px;
    position: sticky;
  }

  .order-validation-modal-content {
    height: 500px;
    overflow-y: auto;
    padding: 0 20px;
  }

  .order-validation-modal-footer {
    bottom: 0;
    position: sticky;
  }
}

// Applies background color to 'required/mandatory' fields
.cl-required {
  input {
    background-color: $spot-color-teal-93;
    color: $spot-color-neutral-42;
    font-weight: 600;
    &::placeholder {
      font-weight: normal;
    }
  }
}

// Applies error red to field labels
.has-errors,
.has-errors * {
  color: $spot-color-red-60;
}

.spot-form__field-error {
  .spot-form__field-error-text {
    margin-bottom: -20px !important;
  }
}

.border-warn .cl-pill.spot-pill.spot-pill--leopard-box {
  box-shadow: inset 3px 0 darkorange !important;
}

.short-code {
  color: $spot-color-neutral-42;
}

.short-code-selected {
  font-weight: 600;

  &.has-indicator {
    padding-left: 13px;
  }
}

.active {
  .spot-toggle-label:before {
    border: 2px solid $spot-interactive-color-on-light;
  }

  label {
    color: $spot-interactive-color-on-light;
    font-weight: 600;
  }
}

#addressSuggestionPopover {
  .cl-arrow {
    display: none;
  }

  .cl-outer-wrapper {
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
    height: auto !important;
    position: absolute;
    top: 0px !important;
    width: 400px;
    z-index: 100;
  }

  &.cl-popover {
    position: absolute !important;
    top: 40px !important;
    z-index: 100;
  }
}

#patientAge,
#emailFax {
  .spot-form--error div.spot-form__input {
    box-shadow: none !important;
    border-bottom: solid 1px $spot-color-red-60;
  }
}

/* SPOT v1.4.1 primary button style override */
.spot-button--primary {
  display: inline-block;
  height: auto;
  line-height: 3em;
}

#association-table-container {
  .spot-form__checkbox-inner {
    width: 33px;
    height: 33px;
  }

  .spot-form__radio-visual {
    margin-right: 0;
  }

  .spot-icon {
    height: 15px;
    width: 15px;
  }
}

// LG-7061 disable form and all nested elements overrides
// These styles are maintained in this file to override Spot defaults and component-level styles
#orderEntryFieldset:disabled {
  input,
  .spot-form__input,
  .cl-required input.spot-form__input,
  .cl-date-form,
  input.cl-date-segment-input {
    background: $spot-color-neutral-91;
    background-color: $spot-color-neutral-91;
    color: $spot-color-neutral-24 !important;
    cursor: not-allowed;
    font-weight: normal !important;
    opacity: 1;
  }

  .spot-toggle:checked:disabled + .spot-toggle-label:before,
  .spot-toggle.disabled:checked + .spot-toggle-label:before {
    background: $spot-color-neutral-30;
    cursor: not-allowed;
  }

  .spot-toggle-label,
  .spot-toggle:disabled + .spot-toggle-label:after,
  .spot-toggle.disabled + .spot-toggle-label:after {
    cursor: not-allowed;
  }

  .spot-form__checkbox-input:disabled + .spot-form__checkbox-inner {
    cursor: not-allowed;
    opacity: 1 !important;

    .spot-form__checkbox-checked-icon {
      fill: $spot-color-neutral-30;
    }
  }

  .spot-form__select-open-icon {
    cursor: not-allowed;
  }

  #customer-search-form .spot-icon.spot-button__icon.spot-button__icon--left,
  #pob-open-button .spot-icon.spot-button__icon.spot-button__icon--left {
    fill: darkgray !important;
  }

  #testCodes .spot-form__microcopy,
  #samples .spot-form__microcopy {
    visibility: hidden;
  }

  #testCodes .cl-pill-remove,
  #samples .cl-pill-remove,
  .list-component .list-action.delete-list-item {
    display: none;
  }

  #testCodes .cancel-reason-selector-wrapper,
  #testCodes cl-cancel-reason-selector {
    display: none !important; // important necessary because of inline styling with fxflex
  }
}

input:read-only.spot-form__input {
  background: $spot-color-neutral-91;
  background-color: $spot-color-neutral-91;
}

#flyout-navigation {
  height: -webkit-fill-available;
  margin-bottom: 55px !important;
  position: absolute;
  top: 70px;
}

.top-layer {
  z-index: 100000 !important;
}
